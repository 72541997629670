import { IPublicKeyResourceShort } from '../../props/key';
import Request from '../request';

const KeyService = {
  retrieve: async (qs?: string): Promise<IPublicKeyResourceShort[]> => {
    try {
      const results = await Request.get(`/api/public_keys?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  get: async (id: string): Promise<IPublicKeyResourceShort> => {
    try {
      const results = await Request.get(`/api/public_keys/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
};

export default KeyService;
