import React from 'react';

import styles from './styles.module.scss';
import Heading from '../../../../components/text/heading';
import Text from '../../../../components/text/text';
import dashboard from "../../../../assets/images/dashboard.webp";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faGlobe, faIdBadge, faRightToBracket } from '@fortawesome/pro-light-svg-icons';
import { faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import { NavLink, useLocation } from 'react-router-dom';
import { Dropdown, TextField } from '@fluentui/react';

type TrackingSectionProps = {};

const TrackingSection: React.FC<TrackingSectionProps> = (props) => {
    const location = useLocation();

    const [searchBy, setSearchBy] = React.useState<string>('on');
    const [identifier, setIdentifier] = React.useState<string>('');
    const [phoneNumber, setPhoneNumber] = React.useState<string>('');
    const [error, setError] = React.useState<any>();

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.subContent}>
                    <div className={styles.headerContainer}>
                        <Heading variant={'h2'}>Lacak Pengiriman Anda</Heading>
                        <div className={styles.divider}></div>
                    </div>
                    <div className={styles.form}>
                        <div className={styles.field}>
                            <Dropdown label={'Cari berdasarkan'}
                                required
                                styles={{ title: { height: 38, borderRadius: 10 } }}
                                options={[
                                    { key: 'on', text: 'Nomor pesanan' },
                                    //{ key: 'cn', text: 'Nomor kontainer' }
                                ]}
                                selectedKey={searchBy}
                                onChange={(evt, opt) => {
                                    setSearchBy(opt?.key as string || searchBy);
                                }} />
                        </div>
                        <div className={styles.field}>
                            <TextField label={searchBy === 'on' ? 'Nomor pesanan (4 digit terakhir)' : 'Nomor kontainer'}
                                styles={{ fieldGroup: { height: 38, borderRadius: 10 } }}
                                value={identifier}
                                required
                                errorMessage={error?.identifier}
                                onChange={(evt, value) => {
                                    setIdentifier(value || "");

                                    let _error = error;
                                    if ((value || "").trim() === "") {
                                        _error.identifier = "Data ini tidak boleh kosong"
                                    } else {
                                        _error = "";
                                    }
                                    setError({..._error});
                                }} />
                        </div>
                        <div className={styles.field}>
                            <TextField label={'Nomor telepon terdaftar'}
                                styles={{ fieldGroup: { height: 38, borderRadius: 10 } }}
                                value={phoneNumber}
                                required
                                errorMessage={error?.phoneNumber}
                                onChange={(evt, value) => {
                                    if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                        setPhoneNumber(value || "");

                                        let _error = error;
                                        if ((value || "").trim() === "") {
                                            _error.phoneNumber = "Data ini tidak boleh kosong"
                                        } else {
                                            _error = "";
                                        }
                                        setError({..._error});
                                    }
                                }} />
                        </div>
                        <button className={styles.button} disabled={phoneNumber.trim() === "" || identifier.trim() === ""}><NavLink to={`/track?_i=${identifier}&_p=${phoneNumber}&_b=${searchBy}`}>Lacak</NavLink></button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TrackingSection;
