import { faContainerStorage, faEnvelope, faLocationDot, faWarehouse } from "@fortawesome/pro-light-svg-icons";
import { AboutUsProps } from "./aboutus";
import GoogleMapReact from "google-map-react";
import WhatsAppProfile02 from './../assets/images/WhatsAppProfile02.webp';
import WhatsAppProfile01 from './../assets/images/WhatsAppProfile01.webp';
import WhatsAppProfile03 from './../assets/images/WhatsAppProfile03.webp';

type WhatsAppProps = {
    name: string;
    key: string;
    title: string;
    number: string;
    url: string;
    image: string;
}

interface OfficeProps extends AboutUsProps {
    coords: GoogleMapReact.Coords;
}

const AboutUs: {
    email: AboutUsProps;
    whatsapps: WhatsAppProps[];
    office: OfficeProps;
    others: AboutUsProps[];
} = {
    email: {
        key: 'email',
        title: 'Email',
        value: 'admin@jejakgroup.com',
        icon: faEnvelope,
        url: 'mailto:admin@jejakgroup.com'
    },
    office: {
        key: 'mainOffice',
        title: 'Kantor Pusat (Surabaya)',
        value: 'Jl. Perak Timur No. 18, Perak Tim., Kec. Pabean Cantikan, Surabaya, Jawa Timur 60164',
        url: 'https://www.google.com/maps/place/PT.+Jejak+Logistik+Nusantara/@-7.2315366,112.7285255,18.92z/data=!4m6!3m5!1s0x2dd7f938757c1dcf:0xb6eeb60dab836fed!8m2!3d-7.2314441!4d112.7290623!16s%2Fg%2F11t71h5fcx?entry=ttu',
        icon: faLocationDot,
        coords: {
            lat: -7.231411126111681,
            lng: 112.72916057827493
        }
    },
    whatsapps: [
        {
            key: 'admin2',
            title: 'Admin 1',
            name: "Arinta",
            number: '+62 823-3242-9933',
            url: 'https://wa.me/6282332429933?text=Hello%20I%20would%20like%20to%20know%20ask%20about%20Jejak%20Logistik',
            image: WhatsAppProfile02
        },
        {
            key: 'admin3',
            title: 'Admin 2',
            name: "Ella Febya",
            number: '+62 859-5957-1818',
            url: 'https://wa.me/6285959571818?text=Hello%20I%20would%20like%20to%20know%20ask%20about%20Jejak%20Logistik',
            image: WhatsAppProfile03
        }
    ],
    others: [
        {
            key: 'branchOffice',
            title: 'Kator Cabang (Jakarta)',
            value: 'Jl. Cipinang Cimpedak I No. 18A, Kec. Jatinegara, Jakarta Timur',
            icon: faLocationDot,
            url: 'https://www.google.com/search?sca_esv=555929706&tbs=lf:1,lf_ui:2&tbm=lcl&q=jejak+logistik+nusantara+(jakarta)&rflfq=1&num=10&rllag=-6236923,106872121,0&rldimm=11302787116949168703&ved=2ahUKEwia57qk9dSAAxX1bmwGHZzcDIoQu9QIegQIHhAM'
        },
        {
            key: 'containerYard',
            title: 'Container Yard',
            value: 'Jl. Kalianak Barat 55 QQ, Surabaya',
            icon: faContainerStorage,
            url: 'https://www.google.com/maps/place/@-7.2390314,112.7059451,19z/data=!4m6!3m5!1s0x2dd7fece33f95993:0x50120a868e394cf7!8m2!3d-7.2395372!4d112.7066199!16s%2Fg%2F11f3zv6sd9?entry=ttu'
        },
        {
            key: 'warehouse',
            title: 'Warehouse',
            value: 'Jl. Kalianak Barat 55 EE, Surabaya',
            icon: faWarehouse,
            url: 'https://www.google.com/maps/place/@-7.2390314,112.7059451,19z/data=!4m6!3m5!1s0x2dd7fece33f95993:0x50120a868e394cf7!8m2!3d-7.2395372!4d112.7066199!16s%2Fg%2F11f3zv6sd9?entry=ttu'
        }
    ]
}

export default AboutUs;