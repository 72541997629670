/*import React from 'react';
import styles from './styles.module.scss'

import heroImage from '../../../../../assets/images/hero-banner.png'
import Heading from '../../../../../components/text/heading';
import Text from '../../../../../components/text/text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

type TypeHeroSectionProps = {
  children?: React.ReactNode;
};

const HeroSection: React.FC<TypeHeroSectionProps> = (props) => {
  return (
    <section className={styles.heroContainer}>
      <section className={styles.sideLeft}>
        <Heading variant='h2' className={styles.label}>KIRIM BARANG MURAH DAN TERPERCAYA ??? JEJAK LOGISTIK AJA !!!</Heading>
        <Heading variant='h6' className={styles.subLabel}>Kami siap membantu pengiriman barang anda sampai titik tujuan dengan aman, tanpa ribet dan tentunya dijamin murah !!! </Heading>
        <div style={{ display: 'flex' }}>
          <a style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }} className={'button'}>
            <FontAwesomeIcon icon={faWhatsapp} />
            <Text>HUBUNGI KAMI</Text>
          </a>
        </div>
      </section>
      <section className={styles.sideRight}>
        <img className={styles.heroImage} src={heroImage} alt="hero" />
      </section>
      <section className={styles.backgroundImage}></section>
    </section>
  );
};

export default HeroSection;*/

import React from 'react';

import styles from './styles.module.scss';
import Heading from '../../../../../../components/text/heading';
import Text from '../../../../../../components/text/text';
import GlobalConfig from '../../../../../../config';

type HeroSectionProps = {};

const HeroSection: React.FC<HeroSectionProps> = (props) => {
  return <div className={styles.container} id={'hero'}>
    <div className={styles.content}>
        <Heading variant='h1'>KIRIM BARANG MURAH DAN TERPERCAYA ??? JEJAK LOGISTIK AJA !!!</Heading>
        <div className={styles.description}>
            <Text variant={'secondary'}>Kami siap membantu pengiriman barang anda sampai titik tujuan dengan aman, tanpa ribet dan tentunya dijamin murah !!! </Text>
        </div>
        <div style={{display: 'flex'}}>
          <a className={'button'} 
            id={'contactus_button'}
            href={`https://wa.me/${GlobalConfig.mainPhoneNumber}?text=Halo%2C%20saya%20mau%20kirim%20barang%20menggunakan%20Jejak%20Logistik`}
            target="_blank">
            <Text variant='secondary'>HUBUNGI KAMI</Text>
          </a>
        </div>
    </div>
    <div className={styles.image}> </div>
  </div>;
}

export default HeroSection;
