import React from 'react';

import styles from './styles.module.scss';
import Heading from '../../../../../../components/text/heading';
import Text from '../../../../../../components/text/text';
import OurClients from '../../../../../../manifests/clients';

type ClientsSectionProps = {
    ref?: React.LegacyRef<HTMLDivElement>
};

const ClientsSection: React.FC<ClientsSectionProps> = (props) => {
    return <section className={styles.container} >
        <div className={styles.leftSection}>
            <div className={styles.header}>
                <Heading variant='h2'>Dipercaya Oleh Semua Kalangan</Heading>
                <div className={styles.indicator}> </div>
            </div>
            <Text variant='secondary'>Kami telah dipercaya oleh lebih dari ratusan pelanggan mulai dari perorangan, umkm, hinggan perusahaan/pabrik ternama di Indonesia !! Bergabunglah untuk menjadi bagian dari Jejak Logistik sekarang juga !!</Text>
        </div>
        <div className={styles.rightSection}>
            {OurClients.map((client) => {
                return <div className={styles.image} key={client.key}>
                    <img src={client.image} title={client.title} alt={"Klien kami " + client.title} />
                </div>
            })}
        </div>
    </section>;
}

export default ClientsSection;
