import TeamOfExpertsImage from './../assets/images/icon-why-us-logistic-expert.svg';
import TimePlanningImage from './../assets/images/icon-why-us-time-planning.svg';
import SafetyServiceImage from './../assets/images/icon-why-us-safety-service.svg';

type WhyUsProps = {
    key: string;
    title: string;
    notes: string;
    image: string;
    imageColor: string;
    imageBackgroundColor: string;
}

const WhyUs: WhyUsProps[] = [
    {
        key: 'experts',
        title: 'Team of Experts',
        notes: 'Kami memiliki tim Customer Service yang terdiri dari para ahli logistik berpengalaman. Dengan kemampuan komunikasi yang baik, tim kami siap memberikan pelayanan dan koordinasi selama 24 jam penuh.',
        image: TeamOfExpertsImage,
        imageColor: 'blue-dark',
        imageBackgroundColor: 'blue-light'
    },
    {
        key: 'timePlanning',
        title: 'Time Planning & Tracking',
        notes: `Kami memberikan layanan pengaturan jadwal terbaik dan tercepat untuk seluruh aktivitas pengiriman barang Anda, dari pengambilan hingga sampai ke tujuan.

Percayakan kepada kami untuk mencari alternatif distribusi barang yang paling efektif dan efisien.`,
        image: TimePlanningImage,
        imageColor: 'teal-dark',
        imageBackgroundColor: 'teal-light'
    },
    {
        key: 'safetyService',
        title: 'Safety & Service',
        notes: 'Kami memastikan setiap barang ditangani oleh jasa stuffing yang ahli & berpengalaman. Dengan Jejak Logistik, keselamatan dan keamanan barang Anda akan selalu terjamin.',
        image: TeamOfExpertsImage,
        imageColor: 'red-dark',
        imageBackgroundColor: 'red-light'
    }
];

export default WhyUs;