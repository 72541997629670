import React from 'react';
import styles from './styles.module.scss'

import whyusImage from '../../../../../../assets/images/why-us-top-left.webp'
import { faHome, faLocation, faLocationDot, faLock, faPhone, faSackDollar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text from '../../../../../../components/text/text';
import Heading from '../../../../../../components/text/heading';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type TypeWhyusSectionProps = {
    children?: React.ReactNode;
    ref?: React.LegacyRef<HTMLDivElement>
};

const WhyusSection: React.FC<TypeWhyusSectionProps> = (props) => {
    const items: {
        icon: IconProp;
        text: string;
    }[] = [
            { icon: faSackDollar, text: 'Harga Termurah' },
            { icon: faLock, text: 'Keamanan Terjamin' },
            { icon: faLocationDot, text: 'Realtime Tracking' },
            { icon: faPhone, text: 'Customer Service 24 Jam' },
        ]

    return (
        <section className={styles.container}>
            <div className={styles.content}>
                <div className={styles.leftSide}>
                    <img src={whyusImage} alt="whyusImage" />
                </div>
                <div className={styles.rightSide}>
                    <Heading variant='h2'>MENGAPA HARUS JEJAK LOGISTIK?</Heading>
                    <Text variant='paragraph'>Karena kami merupakan partner logistik yang dapat memenuhi segala kebutuhan logistik anda dari dan ke seluruh Indonesia. Kami juga telah dipercaya oleh ratusan hingga ribuan UMKM maupun perusahaan berskala nasional. Dengan segala fasilitas dan servis yang kami tawarkan, kami siap membantu anda dan menjadi partner logistik terbaik dan termurah yang pernah ada miliki.</Text>
                    <ul className={styles.list}>
                        {items.map((item) => {
                            return <li className={styles.item}>
                                <div className={styles.icon}><FontAwesomeIcon icon={item.icon} style={{ color: '#fff' }} fontSize={20} /></div>
                                <Text className={styles.text}>{item.text}</Text>
                            </li>
                        })}
                    </ul>
                </div>
            </div>
        </section>
    )
};

export default WhyusSection;