import ContainerImage from './../assets/images/icon-services-container.webp';
import BreakbulkImage from './../assets/images/icon-services-breakbulk.webp';
import TruckingImage from './../assets/images/icon-services-trucking.webp';
import SupplyChainImage from './../assets/images/icon-services-supply-chain.webp';
import LoadingDischargingImage from './../assets/images/icon-services-loading-discharging.webp';
import WarehouseImage from './../assets/images/icon-services-warehouse.webp';

type OurServicesProps = {
    key: string;
    title: string;
    notes: string;
    image: string;
    imageColor: string;
    imageBackgroundColor: string;
}

const OurServices: OurServicesProps[] = [
    {
        key: 'containerShipping',
        title: 'Container Shipping',
        notes: 'Pengiriman barang menggunakan kontainer 20ft maupun 40ft melalui jalur laut.',
        image: ContainerImage,
        imageColor: 'yellow-dark',
        imageBackgroundColor: 'yellow-light'
    },
    {
        key: 'breakbulk',
        title: 'Breakbulk / Cargo',
        notes: `Layanan pengiriman barang antar pulau melalui jalur laut menggunakan kapal kargo yang dilakukan langsung tanpa peti kemas.`,
        image: BreakbulkImage,
        imageColor: 'blue-dark',
        imageBackgroundColor: 'blue-light'
    },
    {
        key: 'trucking',
        title: 'Trucking',
        notes: 'Solusi efisien pengiriman barang antar kota melalui jalur darat.',
        image: TruckingImage,
        imageColor: 'red-dark',
        imageBackgroundColor: 'red-light'
    },
    {
        key: 'loladingDischarging',
        title: 'Loading & Discharging Service',
        notes: 'Layanan yang memudahkan proses bongkar muat barang Anda.',
        image: LoadingDischargingImage,
        imageColor: 'teal-dark',
        imageBackgroundColor: 'teal-light'
    },
    {
        key: 'warehouse',
        title: 'Storage / Warehouse',
        notes: 'Layanan terpercaya untuk menyimpankan barang Anda dengan aman di gudang kami.',
        image: WarehouseImage,
        imageColor: 'red-dark',
        imageBackgroundColor: 'red-light'
    },
    {
        key: 'supplyChain',
        title: 'Supply Chain Service',
        notes: 'Solusi lengkap mulai dari penyimpanan hingga pengelolaan administrasi, serta distribusi barang Anda.',
        image: SupplyChainImage,
        imageColor: 'yellow-dark',
        imageBackgroundColor: 'yellow-light'
    },
    {
        key: 'exportImport',
        title: 'Export & Import',
        notes: 'Dapatkan kemudahan pengiriman barang dan pengurusan dokumen export maupun import.',
        image: SupplyChainImage,
        imageColor: 'blue-dark',
        imageBackgroundColor: 'blue-light'
    },
    /*{
        key: 'trucking',
        title: 'Trucking',
        notes: 'Solusi efisien pengiriman barang antar kota melalui jalur darat.',
        image: TruckingImage,
        imageColor: 'teal-dark',
        imageBackgroundColor: 'teal-light'
    }*/
];

export default OurServices;