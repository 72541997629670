import React from 'react';
import styles from './styles.module.scss'


import ContainerImage from '../../../../../../assets/images/icon-services-container.webp';
import TruckingImage from '../../../../../../assets/images/icon-services-trucking.webp';
import WarehouseImage from '../../../../../../assets/images/icon-services-warehouse.webp';
import HeavyEquipmentImage from '../../../../../../assets/images/icon-services-loading-discharging.webp';
import Heading from '../../../../../../components/text/heading';
import Text from '../../../../../../components/text/text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import GlobalConfig from '../../../../../../config';

const services = [
    [
        {
            key: 'containershipping',
            title: 'KIRIM KONTAINER',
            notes: 'Layanan pengiriman barang menggunakan kontainer 20ft/40ft melalui jalur laut maupun darat baik full 1 kontainer maupun tidak full 1 kontainer. Apabila anda memiliki barang yang membutuhkan keamanan ekstra, pengiriman menggunakan kontainer bisa menjadi solusi terbaik untuk barang anda. Kami siap mengirimkan barang anda menggunakan kontainer dari dan ke mana saja seluruh Indonesia.',
            image: ContainerImage,
            imageColor: 'yellow-dark',
            imageBackgroundColor: 'yellow-light',
            button: {
                label: 'Kirim Kontainer Sekarang',
                url: `https://wa.me/${GlobalConfig.mainPhoneNumber}?text=Halo%2C%20saya%20mau%20kirim%20kontainer%20menggunakan%20Jejak%20Logistik`
            }
        },
        {
            key: 'trucking',
            title: 'KIRIM MENGGUNAKAN TRUK',
            notes: 'Anda membutuhkan pengiriman cepat antar kota? Pengiriman melalui jalur darat bisa menjadi opsi terbaik anda. Kami menyediakan segala kebutuhan truk untuk pengiriman barang anda mulai dari CDD, CDE, Wingbox, Tronton bak terbuka, hingga Truk Trailer. Dengan segala opsi yang kami miliki, pengiriman anda dapat kami pastikan cepat dan aman!!',
            image: TruckingImage,
            imageColor: 'red-dark',
            imageBackgroundColor: 'red-light',
            button: {
                label: 'Pesan Truk Sekarang',
                url: `https://wa.me/${GlobalConfig.mainPhoneNumber}?text=Halo%2C%20saya%20mau%20kirim%20barang%20menggunakan%20truk%20di%20Jejak%20Logistik`
            }
        },
    ],
    [
        {
            key: 'warehousing',
            title: 'JASA PENYIMPANAN BARANG',
            notes: 'Bingung menyimpan barang anda? Tenang, Jejak Logistik punya solusinya. Simpan barang anda di warehouse kami. Kami memiliki 2 warehouse yang terletak di lokasi yang sangat strategis untuk memudahkan mobilisasi barang anda. Dengan keamanan terpercaya, kami siap membantu menyediakan tempat terbaik untuk barang anda.',
            image: WarehouseImage,
            imageColor: 'yellow-dark',
            imageBackgroundColor: 'yellow-light',
            button: {
                label: 'Sewa Warehouse Sekarang',
                url: `https://wa.me/${GlobalConfig.mainPhoneNumber}?text=Halo%2C%20saya%20mau%20sewa%20warehouse%20di%20Jejak%20Logistik`
            }
        },
        {
            key: 'vehicles',
            title: 'KIRIM KENDARAAN DAN ALAT BERAT',
            notes: 'Kami juga menyediakan jasa untuk mengiriman kendaraan anda, baik itu sepeda motor, mobil, truk, bahkan alat berat sekalipun. Kami siap membantu pengiriman kendaraan maupun alat berat anda menjadi mudah dan menyenangkan.',
            image: HeavyEquipmentImage,
            imageColor: 'red-dark',
            imageBackgroundColor: 'red-light',
            button: {
                label: 'Kirim Kendaraan Sekarang',
                url: `https://wa.me/${GlobalConfig.mainPhoneNumber}?text=Halo%2C%20saya%20mau%20kirim%20kendaraan%20dan%20alat%20berat%20menggunakan%20Jejak%20Logistik`
            }
        },
    ],
]

type TypeServiceSectionProps = {
    children?: React.ReactNode;
    ref?: React.LegacyRef<HTMLDivElement>
};

const ServiceSection: React.FC<TypeServiceSectionProps> = (props) => {
    return (
        <section ref={props.ref} className={styles.container}>
            <div className={styles.banner}>
                <Heading className={styles.text} variant='h2'>LAYANAN UNGGULAN JEJAK LOGISTIK</Heading>
            </div>
            <div className={styles.content}>
                {services.map(list => (
                    <div className={styles.list}>
                        {list.map(service => (
                            <div className={styles.item} key={service.key}>
                                <div className={`${styles.image} bg-${service.imageBackgroundColor}`}>
                                    <img src={service.image} color={`color-${service.imageColor}`} alt={"Servis kami " + service.title} />
                                </div>
                                <Heading variant='h6' renderAsLabel={true}>{service.title}</Heading>
                                <div className={styles.divider}></div>
                                <Text>{service.notes}</Text>
                                <div style={{ display: 'flex' }}>
                                    <a style={{ display: 'flex', gap: 10, alignItems: 'center' }} className={'button'} id={'send_' + service.key} href={service.button.url} target='_blank'>
                                        <FontAwesomeIcon icon={faWhatsapp} fontSize={20} />
                                        <Text>{service.button.label}</Text>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}

            </div>
        </section>
    )
};

export default ServiceSection;