import React, { PropsWithChildren } from 'react';

import styles from './styles.module.scss';

type TextProps = PropsWithChildren<{
  variant?: 'paragraph' | 'secondary';
  align?: 'center';
  color?: 'default' | 'success' | 'error';
  className?: string;
  style?: any;
}>;

const Text: React.FC<TextProps> = (props) => {
  return <p className={`${styles[props.variant || 'paragraph']} ${styles[props.color || "default"]} ${props.className || ''}`} style={{ textAlign: props.align === 'center' ? 'center' : undefined, ...(props.style ? props.style : {}) }}>
    {props.children}
  </p>
}

export default Text;
