import Client01 from './../assets/images/our-clients-01.webp';
import Client02 from './../assets/images/our-clients-02.webp';
import Client03 from './../assets/images/our-clients-03.webp';
import Client04 from './../assets/images/our-clients-04.webp';
import Client05 from './../assets/images/our-clients-05.webp';
import Client06 from './../assets/images/our-clients-06.webp';
import Client07 from './../assets/images/our-clients-07.webp';
import Client08 from './../assets/images/our-clients-08.webp';
import Client09 from './../assets/images/our-clients-09.webp';

type OurClientProps = {
    key: string;
    title: string;
    image: string;
}

const OurClients: OurClientProps[] = [
    {
        key: 'bca',
        title: 'BCA',
        image: Client01
    },
    {
        key: 'kapalapi',
        title: 'Kapal Api',
        image: Client02
    },
    {
        key: 'lg',
        title: 'LG',
        image: Client03
    },
    {
        key: 'mayora',
        title: 'Mayora',
        image: Client04
    },
    {
        key: 'pupukindonesia',
        title: 'Pupuk Indonesia',
        image: Client05
    },
    {
        key: 'sharp',
        title: 'Sharp',
        image: Client06
    },
    {
        key: 'wings',
        title: 'Wings',
        image: Client07
    },
    {
        key: 'petrokimia',
        title: 'Petrokimia Gresik',
        image: Client08
    },
    {
        key: 'japfa',
        title: 'Japfa',
        image: Client09
    }
];

export default OurClients;