import React from 'react';

import styles from './styles.module.scss';
import Heading from '../../../../components/text/heading';
import Text from '../../../../components/text/text';
import OurServices from '../../../../manifests/services';
import OurClients from '../../../../manifests/clients';

type ClientsSectionProps = {};

const ClientsSection: React.FC<ClientsSectionProps> = (props) => {
    return <div className={styles.container}>
        <div className={styles.leftSection}>
            <div className={styles.header}>
                <Heading variant='h2'>Dipercaya Perusahaan di Seluruh Indonesia</Heading>
                <div className={styles.indicator}> </div>
            </div>
            <Text variant='secondary'>Kami bangga menjadi mitra logistik bagi pelanggan kami, menyediakan layanan terpercaya dan efisien untuk memastikan setiap pengiriman tiba tepat waktu dan dengan sempurna.</Text>
        </div>
        <div className={styles.rightSection}>
            {OurClients.map((client) => {
                return <div className={styles.image} key={client.key}>
                    <img src={client.image} title={client.title} alt={"Klien kami " + client.title} />
                </div>
            })}
        </div>
    </div>;
}

export default ClientsSection;
