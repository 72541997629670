import React from 'react';

import styles from './styles.module.scss';
import Heading from '../../../../components/text/heading';
import Text from '../../../../components/text/text';
import OurServices from '../../../../manifests/services';
import GlobalConfig from '../../../../config';

type QuoteSectionProps = {};

const QuoteSection: React.FC<QuoteSectionProps> = (props) => {
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [submitted, setSubmitted] = React.useState<boolean>(false);
    const [name, setName] = React.useState<string>("");
    const [contact, setContact] = React.useState<string>("");
    const [details, setDetails] = React.useState<string>("");
    const [errorName, setErrorName] = React.useState<string | undefined>();
    const [errorContact, setErrorContact] = React.useState<string | undefined>();
    const [errorDetails, setErrorDetails] = React.useState<string | undefined>();

    const isError = (text: string, title: string) => {
        let error: string | undefined = undefined;

        if ((text || "").trim() === "") {
            error = title + " tidak boleh kosong";
        }

        return error;
    }

    const _onSubmit = async () => {
        const _errorName = isError(name, "Nama");
        const _errorContact = isError(contact, "Email / Nomor telepon");
        const _errorDetails = isError(details, "Detail barang");

        setErrorName(_errorName);
        setErrorContact(_errorContact);
        setErrorDetails(_errorDetails);

        if (!_errorName && !_errorContact && !_errorDetails) {
            const reqBody = {
                email: contact,
                fullName: name,
                description: details,
                phoneNumber: contact,
                companyName: "",
                companyAddress: ""
            };

            try {
                setSubmitting(true);
                await fetch(GlobalConfig.apiHostname + "/api/inquiries", {
                    method: "POST",
                    body: JSON.stringify(reqBody),
                    headers: new Headers([
                        ['Content-Type', 'application/json'],
                        ['Accept', 'application/json']
                    ])
                });
                setSubmitted(true);
                setSubmitting(false);
            } catch(e) {
                setSubmitted(false);
                setSubmitting(false);
            } 

        }
    }

    return <div className={styles.container}>
        <div className={styles.leftSection}>
            <div className={styles.image}> </div>
        </div>
        <div className={styles.rightSection}>
            <div className={styles.header}>
                <Heading variant='h2'>Cek Harga</Heading>
                <div className={styles.indicator}> </div>
            </div>
            <Text variant='secondary'>Dapatkan penawaran terbaik sesuai dengan kebutuhan Anda!</Text>
            <div className={styles.form}>
                <div className={styles.input}>
                    <Heading variant={'h6'} renderAsLabel={true}>Nama</Heading>
                    <input disabled={submitting || submitted} type={'text'} value={name} onChange={(evt) => {
                        const value = evt.currentTarget.value || "";
                        const error = isError(value, 'Nama');
                        setName(value);
                        setErrorName(error);
                    }} />
                    {errorName ? <Text color={'error'}>{errorName}</Text> : null}
                </div>
                <div className={styles.input}>
                    <Heading variant={'h6'} renderAsLabel={true}>Email / Nomor Telepon</Heading>
                    <input disabled={submitting || submitted} type={'text'} value={contact} onChange={(evt) => {
                        const value = evt.currentTarget.value || "";
                        const error = isError(value, 'Email / Nomor telepon');
                        setContact(value);
                        setErrorContact(error);
                    }} />
                    {errorContact ? <Text color={'error'}>{errorContact}</Text> : null}
                </div>
                <div className={styles.input}>
                    <Heading variant={'h6'} renderAsLabel={true}>Detail Pengiriman</Heading>
                    <textarea disabled={submitting || submitted} rows={5} style={{ resize: 'none' }} placeholder='Cth: Saya ingin mengirim pipa seberat 1 ton dari Surabaya ke Banjarmasin ...' value={details} onChange={(evt) => {
                        const value = evt.currentTarget.value || "";
                        const error = isError(value, 'Detail pengiriman');
                        setDetails(value);
                        setErrorDetails(error);
                    }} />
                    {errorDetails ? <Text color={'error'}>{errorDetails}</Text> : null}
                </div>
                <div style={{ display: 'flex' }}>
                    {!submitting && !submitted ? <button name={"Kirim quotation"} onClick={_onSubmit}>Submit</button> : null}
                    {submitting ? <Text>Harap tunggu ...</Text> : null}
                    {submitted ? <Text color={'success'}>Quotation anda telah kami terima. Tim kami akan segera menghubungi anda melalui email / nomor telepon yang telah anda berikan.</Text> : null}
                </div>
            </div>
        </div>
    </div>;
}

export default QuoteSection;
