import React, { RefObject, createRef, useEffect, useRef, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Menubar from '../../components/menubar';
import HeroSection from './sections/hero';

import styles from './styles.module.scss';
import WhyUsSection from './sections/whyus';
import ServicesSection from './sections/services';
import QuoteSection from './sections/quote';
import ClientsSection from './sections/clients';
import Footer from '../../components/footer';
import ReachUsSection from './sections/reachus';
import WhatsappContact from '../../components/whatsapp';
import NotFoundSection from './sections/404';
import { IPublicOfficeResourceshort } from '../../props/users/office';
import { IPublicContactResourceshort } from '../../props/users/contact';
import OfficeService from '../../services/public/offices';
import ContactService from '../../services/public/contact';
import { useLocation } from 'react-router-dom';
import PolicyPage from './subPages/policy';
import LandingPage from './subPages/landing';
import AppSection from './subPages/app';
import MenuItems, { MenuItemsId, SMEMenuItemsId } from '../../manifests/menu';
import { IPublicKeyResourceShort } from '../../props/key';
import KeyService from '../../services/public/keys';
import { IPublicNewsResourceShort } from '../../props/news';
import NewsPage from './subPages/news';
import NewsSection from './sections/news';
import { Scrollspy } from '@makotot/ghostui';
import TrackingSection from './sections/tracking';
import TrackingSubPage from './subPages/tracking';

export type OfficeType = {
  loaded: boolean;
  items: IPublicOfficeResourceshort[];
};

export type ContactType = {
  loaded: boolean;
  items: IPublicContactResourceshort[];
};

export type NewsType = {
  loaded: boolean;
  items: IPublicNewsResourceShort[];
};

type HomePageProps = {};

const HomePage: React.FC<HomePageProps> = (props) => {
  const location = useLocation();
  const [activePage, setActivePage] = useState<string>('home');
  const [offices, setOffices] = useState<IPublicOfficeResourceshort[]>([])
  const [contacts, setContacts] = useState<IPublicContactResourceshort[]>([])
  const [keys, setKeys] = useState<IPublicKeyResourceShort[]>([]);
  
  const trackingSubPageRef: RefObject<HTMLDivElement> = useRef(null);

  const appSectionRef: RefObject<HTMLDivElement> = useRef(null);
  const trackingSectionRef: RefObject<HTMLDivElement> = useRef(null);
  const heroSectionRef: RefObject<HTMLDivElement> = useRef(null);
  const whyUsSectionRef: RefObject<HTMLDivElement> = useRef(null);
  const servicesSectionRef: RefObject<HTMLDivElement> = useRef(null);
  const contactSectionRef: RefObject<HTMLDivElement> = useRef(null);
  const aboutSectionRef: RefObject<HTMLDivElement> = useRef(null)

  const smeDivRefs = useRef(SMEMenuItemsId.map(() => createRef<HTMLDivElement>())).current;
  const divRefs = [heroSectionRef, whyUsSectionRef, servicesSectionRef, contactSectionRef, aboutSectionRef]

  const getKeys = async () => {
    try {

      const qs: string[] = [];
      qs.push(`name=public_website_map_key`);
      const items = await KeyService.retrieve(qs.join("&"));
      setKeys(items)
    } catch (error) {

    }
  }

  const getOffices = async () => {
    try {
      let _offices = await OfficeService.retrieve();
      _offices = _offices.sort((a, b) => (a.order_number > b.order_number) ? 1 : ((b.order_number > a.order_number) ? -1 : 0))
      setOffices(_offices);
    } catch (error) {

    }
  }

  const getContacts = async () => {
    try {
      const _contacts = await ContactService.retrieve()
      setContacts(_contacts);
    } catch (error) {

    }
  }

  useEffect(() => {
    getOffices()
    getContacts()
    getKeys()
    return () => { }
  }, [])


  return (
    <div className={styles.container}>
      <Routes>
        {/*<Route path="/download" element={(
          <>
            <Menubar menuItems={MenuItems} />

            <div id={'app'} ref={appSectionRef}>
              <div className={styles.content}>
                <AppSection />
              </div>
            </div>

            <div className={`bg-light`}>
              <div className={styles.content}>
                <Footer contacts={contacts} offices={offices} />
              </div>
            </div>
          </>
        )} />*/}

        <Route path="/track" element={(
          <>
            <Menubar menuItems={MenuItems} />

            <div id={'tracking'} ref={trackingSubPageRef}>
              <div className={styles.content}>
                <TrackingSubPage />
              </div>
            </div>

            <div className={`bg-light`}>
              <div className={styles.content}>
                <Footer contacts={contacts} offices={offices} />
              </div>
            </div>
          </>
        )} />

        <Route path="/policy" element={(
          <>
            <Menubar menuItems={MenuItems} />
            <div id={'app'} ref={appSectionRef}>
              <div className={styles.content}>
                <PolicyPage />
              </div>
            </div>
            <div className={`bg-light`}>
              <div className={styles.content}>
                <Footer contacts={contacts} offices={offices} />
              </div>
            </div>
          </>
        )} />
        <Route path="/sme" element={(
          <Scrollspy offset={-110} sectionRefs={smeDivRefs}>
            {({ currentElementIndexInViewport }) => {
              return (
                <>
                  <Menubar currentElementIndexInViewport={currentElementIndexInViewport} menuItems={SMEMenuItemsId} />
                  <LandingPage contacts={contacts} offices={offices} smeDivRefs={smeDivRefs} />
                  <div className={`bg-light`}>
                    <div className={styles.content}>
                      <Footer contacts={contacts} offices={offices} />
                    </div>
                  </div>
                </>
              )
            }}
          </Scrollspy>
        )} />
        <Route path="/news/:newsId" element={(
          <>
            <Menubar menuItems={MenuItems} />
            <div id={'app'} ref={appSectionRef}>
              <div className={styles.content}>
                <NewsPage />
              </div>
            </div>
            <div className={`bg-light`}>
              <div className={styles.content}>
                <Footer contacts={contacts} offices={offices} />
              </div>
            </div>
          </>
        )} />
        <Route path="/" element={(
          <Scrollspy offset={-110} sectionRefs={divRefs}>
            {({ currentElementIndexInViewport }) => {
              return (
                <>
                  <Menubar currentElementIndexInViewport={currentElementIndexInViewport} menuItems={MenuItems} />
                  <div>
                    <div className={styles.content} id={'home'} ref={heroSectionRef}>
                      <HeroSection />
                    </div>
                  </div>
                  {/*<div id={'app'} ref={appSectionRef}>
                    <div className={styles.content}>
                      <AppSection />
                    </div>
                  </div>*/}
                  <div id={'tracking'} ref={trackingSectionRef}>
                    <div className={styles.content}>
                      <TrackingSection />
                    </div>
                  </div>
                  <div className={`bg-light`} id={'advantages'} ref={whyUsSectionRef}>
                    <div className={styles.content}>
                      <WhyUsSection />
                    </div>
                  </div>
                  <div>
                    <div className={styles.content} id={'services'} ref={servicesSectionRef}>
                      <ServicesSection />
                    </div>
                  </div>
                  <div className={`bg-light`} id={'contact'} ref={contactSectionRef}>
                    <div className={styles.content}>
                      <QuoteSection />
                    </div>
                  </div>
                  <div>
                    <div className={styles.content} id={'clients'}>
                      <ClientsSection />
                    </div>
                  </div>
                  {/*<div>
                    <div className={styles.content} id={'news'}>
                      <NewsSection />
                    </div>
                  </div>*/}
                  <div className={'bg-image bg-reachus'} id={'about'} ref={aboutSectionRef}>
                    <div className={styles.content}>
                      <ReachUsSection contacts={contacts} offices={offices} />
                    </div>
                    <div className={`bg-backdrop`}></div>
                  </div>
                  <div className={`bg-light`}>
                    <div className={styles.content}>
                      <Footer contacts={contacts} offices={offices} />
                    </div>
                  </div>
                </>
              )
            }}

          </Scrollspy>
        )} />
        <Route path="*" element={(
          <div id={'app'} ref={appSectionRef}>
            <div className={styles.content}>
              <NotFoundSection />
            </div>
          </div>
        )} />
      </Routes>
      <WhatsappContact contacts={contacts} />
    </div>
  )
}

export default HomePage;
