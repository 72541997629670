import Request from '../request';
import { IPublicContactResourceshort } from '../../props/users/contact';

const ContactService = {
  retrieve: async (qs?: string): Promise<IPublicContactResourceshort[]> => {
    try {
      const results = await Request.get(`/api/public_contacts?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  get: async (id: string): Promise<IPublicContactResourceshort> => {
    try {
      const results = await Request.get(`/api/public_contacts/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
};

export default ContactService;
