import { IPublicOfficeResourceshort } from '../../props/users/office';
import Request from './../request';

const OfficeService = {
  retrieve: async (qs?: string): Promise<IPublicOfficeResourceshort[]> => {
    try {
      const results = await Request.get(`/api/public_offices?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  get: async (id: string): Promise<IPublicOfficeResourceshort> => {
    try {
      const results = await Request.get(`/api/public_offices/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
};

export default OfficeService;
