import React from 'react';
import styles from './styles.module.scss'

import whyusImage from '../../../../../../assets/images/why-us-top-left.webp'
import { faCalendar, faHome, faLocation, faLocationDot, faLock, faPhone, faSackDollar, faTruck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text from '../../../../../../components/text/text';
import Heading from '../../../../../../components/text/heading';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import RequestQuoteImage from '../../../../../../assets/images/request-quote.webp'
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import GlobalConfig from '../../../../../../config';


type TypeAreYouSureSectionProps = {
    children?: React.ReactNode;
    ref?: React.LegacyRef<HTMLDivElement>
};

const AreYouSureSection: React.FC<TypeAreYouSureSectionProps> = (props) => {
    const items: {
        icon: IconProp;
        text: string;
    }[] = [
            { icon: faPhone, text: 'CHAT ADMIN UNTUK CEK HARGA' },
            { icon: faCalendar, text: 'PILIH JADWAL PENGIRIMAN' },
            { icon: faTruck, text: 'BARANG LANGSUNG DIKIRIM' }
        ]

    return (
        <section className={styles.container}>
            <div className={styles.content}>
                <div className={styles.leftSide}>
                    <Heading variant='h2'>YAKIN MASIH INGIN MELIRIK KE YANG LAIN?</Heading>
                    <Text variant='paragraph'>Kirim barang dengan Jejak Logistik semudah membalikkan telapak tangan lo! Buat pengalaman mengirim barang anda menjadi jauh lebih menyenangkan dengan kami. Dengan fasilitas dan segala keuntungan yang anda dapat, sudah jelas mengirim menggunakan Jejak Logistik merupakan opsi terbaik. Cukup dengan 3 langkah berikut, barang anda sudah bisa sampai tujuan!</Text>

                    <ul className={styles.list}>
                        {items.map((item) => {
                            return <li className={styles.item}>
                                <div className={styles.icon}><FontAwesomeIcon icon={item.icon} style={{ color: '#fff' }} fontSize={20} /></div>
                                <Text className={styles.text}>{item.text}</Text>
                            </li>
                        })}
                    </ul>

                    <div className={styles.divider}></div>

                    <a style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 10 }}
                        className={'button'}
                        id={'areyousure_button'}
                        href={`https://wa.me/${GlobalConfig.mainPhoneNumber}?text=Halo%2C%20saya%20mau%20kirim%20barang%20menggunakan%20Jejak%20Logistik`}
                        target="_blank" >
                        <FontAwesomeIcon icon={faWhatsapp} fontSize={20} />
                        <Text>Saya Mau Kirim Barang Sekarang</Text>
                    </a>

                </div>
                <div className={styles.rightSide}>
                    <img src={RequestQuoteImage} alt="whyusImage" />
                </div>
            </div>
        </section>
    )
};

export default AreYouSureSection;