import React, { useEffect } from 'react';
import styles from './styles.module.scss';

import { ActionButton, CommandBar, Dialog, DialogType, Label, Link, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleCheck, faClock } from '@fortawesome/pro-solid-svg-icons';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';
import { faFile, faFileExcel, faFilePdf, faFilePowerpoint, faFileWord, faImage, faShip, faTrain, faTruck, faUserHelmetSafety } from '@fortawesome/pro-light-svg-icons';
import DocumentService from '../../../../services/documents/document';

type TrackingDocumentProps = {
    path: string;
};

const TrackingDocument: React.FC<TrackingDocumentProps> = (props) => {
    const possibleExtensions = {
        image: {
            format: ['jpg', 'jpeg', 'tiff', 'svg', 'png', 'gif'],
            icon: faImage,
            color: '#aaa'
        },
        word: {
            format: ['doc', 'docx'],
            icon: faFileWord,
            color: '#002050',
            bgcolor: '#e3ecfa'
        },
        excel: {
            format: ['xls', 'xlsx'],
            icon: faFileExcel,
            color: '#094624',
            bgcolor: '#f2f9f5'
        },
        powerpoint: {
            format: ['ppt', 'pptx'],
            icon: faFilePowerpoint,
            color: '#740912',
            bgcolor: '#fcf0ed'
        },
        pdf: {
            format: ['pdf'],
            icon: faFilePdf,
            color: '#650b04',
            bgcolor: '#fcf3f2'
        }
    };

    const [blob, setBlob] = React.useState<any>();
    const [showImageDialog, setShowImageDialog] = React.useState<boolean>();
    const [downloading, setDownloading] = React.useState<boolean>();
    const extension = (props.path.split(".").pop() || "").toLowerCase();
    const filename = props.path.split("/").pop();
    const isImage = possibleExtensions.image.format.indexOf(extension) > -1;

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            if (isImage) {
                const _blob = await DocumentService.get(props.path);
                setBlob(_blob);
            }
        } catch (e) { }
    }

    const renderDocument = () => {
        const { word, pdf, excel, powerpoint } = possibleExtensions;

        let icon = faFile;
        let iconColor = '#000';
        let bgColor = '#fff';

        if (word.format.indexOf(extension) > -1) {
            icon = word.icon;
            iconColor = word.color;
            bgColor = word.bgcolor;
        } else if (pdf.format.indexOf(extension) > -1) {
            icon = pdf.icon;
            iconColor = pdf.color;
            bgColor = pdf.bgcolor;
        } else if (excel.format.indexOf(extension) > -1) {
            icon = excel.icon;
            iconColor = excel.color;
            bgColor = excel.bgcolor;
        } else if (powerpoint.format.indexOf(extension) > -1) {
            icon = powerpoint.icon;
            iconColor = powerpoint.color;
            bgColor = powerpoint.bgcolor;
        }

        return <Stack tokens={{ childrenGap: 5 }} styles={{ root: { backgroundColor: bgColor, height: '100%', width: '100%', borderRadius: 4 } }} verticalAlign={'center'} horizontalAlign={'center'}>
            <FontAwesomeIcon icon={icon} size={'2x'} color={iconColor} />
            <Text className={styles.filename} style={{ textAlign: 'center' }} variant={'xSmall'}>{filename}</Text>
        </Stack>;
    }

    const _onDownload = async () => {
        try {
            setDownloading(true);
            const blobData = (await DocumentService.get(props.path));
            const byteCharacters = atob(blobData);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray]);

            const blobURL = URL.createObjectURL(blob);
            const downloadLink = document.createElement('a');
            downloadLink.href = blobURL;
            downloadLink.download = filename || "file_" + moment().toISOString();
            downloadLink.click();

            URL.revokeObjectURL(blobURL);
            setDownloading(false);
            /*const arrayBuffer = new Uint8Array([...atob(blobData)].map(char => char.charCodeAt(0)))
            const fileLink = document.createElement('a');
            
            fileLink.href = window.URL.createObjectURL(new Blob([arrayBuffer]))
            fileLink.setAttribute('download', "example.pdf")
            document.body.appendChild(fileLink)
            fileLink.click();
            URL.revokeObjectURL(blobURL);*/
        } catch (error) {
            setDownloading(false);
        }
    }

    return <>
        <Link onClick={() => setShowImageDialog(true)}>
            <Stack className={styles.document} verticalAlign={'center'} horizontalAlign={'center'}>
                {isImage && !blob ? <Spinner size={SpinnerSize.small} /> : null}
                {isImage && blob ? <img className={styles.image} src={`data:image/jpeg;base64,${blob}`} /> : null}
                {!isImage ? renderDocument() : null}
            </Stack>
        </Link>
        {showImageDialog ? <Dialog
            hidden={!showImageDialog}
            maxWidth={800}
            onDismiss={() => setShowImageDialog(false)}
            dialogContentProps={{type: DialogType.close, title: filename}}
            modalProps={{isBlocking: false}}
        >
            <Stack className={styles.documentDialog} tokens={{childrenGap: 20}}>
                <CommandBar items={[
                    {key: 'download', onRender: () => {
                        return <>
                            {!downloading ? <ActionButton onClick={_onDownload} text={'Download'} iconProps={{iconName: "Download"}} /> : null}
                            {downloading ? <Spinner size={SpinnerSize.small} label={"Mengunduh ..."} labelPosition={'right'} /> : null}
                        </>
                    }}
                ]} styles={{root: {padding: 0, margin: 0}}} />
                {isImage && !blob ? <Spinner size={SpinnerSize.small} /> : null}
                {isImage && blob ? <img className={styles.image} src={`data:image/jpeg;base64,${blob}`} /> : null}
                {!isImage ? <Stack styles={{root: {height: 200}}}>{renderDocument()}</Stack> : null}
            </Stack>
        </Dialog> : null}
    </>
}

export default TrackingDocument;
