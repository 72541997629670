import Request from './../request';

const DocumentService = {
  get: async (path: string) => {
    try {
      const result = await Request.get(`/api/public/document?path=${path}`);
      return result;
    } catch (e) {
      throw (e);
    }
  }
};

export default DocumentService;
