import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';

import './styles/fonts.scss';
import './styles/global.scss';
import HomePage from './pages/home';
import { initializeIcons, loadTheme } from '@fluentui/react';

initializeIcons();

// update theme
loadTheme({
  palette: {
    themePrimary: '#e48825',
    themeLighterAlt: '#fefaf5',
    themeLighter: '#fbead9',
    themeLight: '#f7d9b8',
    themeTertiary: '#eeb476',
    themeSecondary: '#e6943c',
    themeDarkAlt: '#cc7921',
    themeDark: '#ac661c',
    themeDarker: '#7f4b14',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  },
});

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<HomePage />} />
      </Routes>
    </BrowserRouter>
  )

}

export default App;
