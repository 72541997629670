import Config from './../config';


const Request = {
  get: async (api: string) => {
    try {
      let headers: any = {
        accept: 'application/json',
      };

      const response = await fetch(Config.apiHostname + api, {
        method: 'GET',
        headers: headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.data;

    } catch (error) {
      throw error;
    }
  },
  post: async (api: string, fd: FormData) => {
    try {
      let headers: any = {
        accept: 'application/json'
      };

      const response = await fetch(Config.apiHostname + api, {
        method: 'POST',
        headers: headers,
        body: fd
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.data;

    } catch (error) {
      throw error;
    }
  },
};

export default Request;
