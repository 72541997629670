import { IPublicNewsResourceShort } from '../../props/news';
import Request from '../request';

const NewsService = {
  retrieve: async (qs?: string): Promise<IPublicNewsResourceShort[]> => {
    try {
      const results = await Request.get(`/api/public/news?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  get: async (id: string): Promise<IPublicNewsResourceShort> => {
    try {
      const results = await Request.get(`/api/public/news/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
};

export default NewsService;
