import React from 'react';
import styles from './styles.module.scss';
import Heading from '../../../../components/text/heading';
import Text from '../../../../components/text/text';

type NotFoundSectionProps = {
    children?: React.ReactNode;
};

const NotFoundSection: React.FC<NotFoundSectionProps> = (props) => {
    return (
        <div className={styles.container}>
                <Heading variant={'h1'}>404</Heading>
                <Text>Maaf, halaman yang anda cari tidak dapat ditemukan.</Text>
        </div>
    )
};

export default NotFoundSection;