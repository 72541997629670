export type MenuItemProps = {
    key: string;
    text: string;
    url: string;
    active?: boolean;
}

const MenuItems: MenuItemProps[] = [
    {
        key: 'home',
        text: 'Beranda',
        url: '#home',
        active: true
    },
    {
        key: 'track',
        text: 'Lacak Pengiriman',
        url: '#tracking'
    },
    {
        key: 'advantages',
        text: 'Keunggulan',
        url: '#advantages'
    },
    {
        key: 'services',
        text: 'Servis Kami',
        url: '#services'
    },
    {
        key: 'contact',
        text: 'Kontak Kami',
        url: '#contact'
    },
    {
        key: 'about',
        text: 'Tentang Jejak',
        url: '#about'
    }
];


const MenuItemsId: MenuItemProps[] = [
    {
        key: 'services',
        text: 'Layanan',
        url: '#services'
    },
    {
        key: 'whyUs',
        text: 'Mengapa Kami?',
        url: '#whyUs'
    },
    {
        key: 'checkShippingCost',
        text: 'Cek Ongkir',
        url: '#checkShippingCost'
    },
    {
        key: 'clients',
        text: 'Pelanggan',
        url: '#clients'
    },
    {
        key: 'contact',
        text: 'Kontak',
        url: '#contact'
    },
];

const SMEMenuItemsId: MenuItemProps[] = [
    {
        key: 'services',
        text: 'Layanan',
        url: '#p-ourservices-section'
    },
    {
        key: 'whyUs',
        text: 'Mengapa Kami?',
        url: '#p-whyus-section'
    },
    {
        key: 'checkShippingCost',
        text: 'Cek Ongkir',
        url: '#p-checkrate-section'
    },
    {
        key: 'clients',
        text: 'Pelanggan',
        url: '#p-ourclients-section'
    },
    {
        key: 'contact',
        text: 'Kontak',
        url: '#p-areyousure-section'
    },
];

export { MenuItemsId, SMEMenuItemsId }

export default MenuItems;