import React, { useEffect, useState } from 'react';
import { NewsType } from '../..';
import styles from './styles.module.scss';
import { useParams } from 'react-router-dom';
import NewsService from '../../../../services/public/news';
import { IPublicNewsResourceShort } from '../../../../props/news';
import Heading from '../../../../components/text/heading';
import Text from '../../../../components/text/text';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import GlobalConfig from '../../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faContainerStorage, faLocationDot, faShip, faTruck } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';

type TypeNewsPageProps = {
  children?: React.ReactNode;
};

const NewsPage: React.FC<TypeNewsPageProps> = (props) => {
  const params: any = useParams();

  const [news, setNews] = useState<IPublicNewsResourceShort>()

  const getNews = async () => {
    try {
      const item = await NewsService.get(params.newsId)
      setNews(item)
    } catch (error) {

    }
  }

  useEffect(() => {
    getNews()
    return () => { }
  }, [])

  const renderTags = () => {
    if (news && (news.projectDate || news.projectLocation || news.goodsType)) {
      let goodsTypeIcon = faContainerStorage;
      let goodsTypeText = 'Full Container Load';
      if (news.goodsType === 'lcl') {
        goodsTypeText = 'Less Container Load';
      } else if (news.goodsType === 'ftl') {
        goodsTypeIcon = faTruck;
        goodsTypeText = 'Full Truck Load';
      } else if (news.goodsType === 'ltl') {
        goodsTypeIcon = faTruck;
        goodsTypeText = 'Less Truck Load';
      } else if (news.goodsType === 'bulk') {
        goodsTypeIcon = faShip;
        goodsTypeText = 'Breakbulk/Cargo';
      }

      return <div className={styles.tags}>
        {news.projectDate ? <div className={styles.item}>
          <FontAwesomeIcon icon={faCalendar} fontSize={14} />
          <Text variant={'paragraph'}>{moment(news.projectDate).format("DD/MM/YYYY")}</Text>
        </div> : null}
        {news.projectLocation ? <div className={styles.item}>
          <FontAwesomeIcon icon={faLocationDot} fontSize={14} />
          <Text variant={'paragraph'}>{news.projectLocation}</Text>
        </div> : null}
        {news.goodsType ? <div className={styles.item}>
          <FontAwesomeIcon icon={goodsTypeIcon} fontSize={14} />
          <Text variant={'paragraph'}>{goodsTypeText}</Text>
        </div> : null}
      </div>
    } else {
      return <></>;
    }
  }

  return <section className={styles.container}>
    <div className={styles.headerContainer}>
      <Heading variant={'h2'}>{news?.subject}</Heading>
      <div className={styles.divider}></div>
    </div>
    <div className={styles.content}>
      {renderTags()}
      <ReactMarkdown
        children={news?.content}
      />
    </div>
    <section className={styles.imageContainer}>
      {news?.images.map((im, ix) => (
        <img className={styles.newsImage} key={ix} src={GlobalConfig.assetsHostname + '/' + im.path} alt="image" />
      ))}
    </section>
  </section>
};

export default NewsPage;