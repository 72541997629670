import React, { RefObject, useEffect, useRef, useState } from 'react';
import LogoDarkRectangle from './../../assets/logos/logo-dark-rectangle.webp';
import styles from './styles.module.scss';
import { MenuItemProps } from '../../manifests/menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/pro-light-svg-icons';
import { Link, NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

type MenubarProps = {
    menuItems: MenuItemProps[]
    currentElementIndexInViewport?: number
};

const Menubar: React.FC<MenubarProps> = (props: MenubarProps) => {
    const location = useLocation();

    const [showMobileMenu, setShowMobileMenu] = React.useState<boolean>(false);
    const [menuItem, setMenuItem] = useState<MenuItemProps>()

    useEffect(() => {
        const currentElementIndexInViewport = props.currentElementIndexInViewport
        setMenuItem(currentElementIndexInViewport !== undefined ? props.menuItems[currentElementIndexInViewport] : undefined)
        return () => {

        }
    }, [props])

    useEffect(() => {
        if (location.pathname === '/download' || location.pathname === '/policy' || location.pathname === '/sme') {
        }

        return () => { }
    }, [props])

    const renderItem = (item: MenuItemProps) => {
        if (location.pathname === '/download' || location.pathname === '/policy') {
            return (
                <li
                    key={item.key}
                    className={`${styles.item}`}
                >
                    <NavLink
                        to={'/'}
                        className={`${styles.link}`}
                        onClick={() => {
                            setShowMobileMenu(false);
                        }}
                    >
                        {item.text}
                    </NavLink>
                </li>
            );
        } else {
            return (
                <li
                    key={item.key}
                    className={`${styles.item} ${menuItem && menuItem.key === item.key && styles.active}`}
                >
                    <a
                        href={item.url}
                        className={`${styles.link}`}
                        onClick={() => {
                            setShowMobileMenu(false);
                        }}
                    >
                        {item.text}
                        {menuItem && menuItem.key === item.key ? <div className={styles.indicator}> </div> : null}
                    </a>
                </li>
            );
        }
    };


    return <div className={styles.menubar}>
        <div className={styles.container}>
            <div>
                <a href={"/"}><img alt={'Jejak Group - One stop solution for your logistic needs'} src={LogoDarkRectangle} className={styles.logo} /></a>
            </div>
            <ul className={styles.items}>
                {props.menuItems.map(renderItem)}
            </ul>

            <div className={styles.menuButton}>
                <button name={"Buka menu"} onClick={() => setShowMobileMenu(true)}><FontAwesomeIcon icon={faBars} fontSize={24} /></button>
            </div>
            {/*<div className={styles.login}>
                <a href={'https://my.jejakgroup.com/auth/login'}><button>Login / Register</button></a>
            </div>*/}
        </div>
        {showMobileMenu ? <div className={styles.mobileMenu}>
            <div className={styles.header}>
                <div>
                    <img alt={'Jejak Group - One stop solution for your logistic needs'} src={LogoDarkRectangle} className={styles.logo} />
                </div>
                <div className={styles.menuButton}>
                    <button name={"Tutup menu"}><FontAwesomeIcon fontSize={24} icon={faXmark} onClick={() => setShowMobileMenu(false)} /></button>
                </div>
            </div>
            <ul className={styles.items}>
                {props.menuItems.map(renderItem)}
            </ul>
        </div> : null}
    </div>;
}

export default Menubar;
