import React from 'react';

import styles from './styles.module.scss';
import Heading from '../../../../components/text/heading';
import Text from '../../../../components/text/text';

import TopRightImage from './../../../../assets/images/why-us-top-right.webp';
import TopLeftImage from './../../../../assets/images/why-us-top-left.webp';
import BottomRightImage from './../../../../assets/images/why-us-bottom-right.webp';
import BottomLeftImage from './../../../../assets/images/why-us-bottom-left.webp';
import WhyUs from '../../../../manifests/whyus';

type WhyUsSectionProps = {};

const WhyUsSection: React.FC<WhyUsSectionProps> = (props) => {
    return <div className={styles.container}>
        <div className={styles.leftSection}>
            <div className={styles.header}>
                <Heading variant='h2'>Mengapa Jejak Logistik</Heading>
                <div className={styles.indicator}> </div>
            </div>
            <div className={styles.body}>
                {WhyUs.map((item) => {
                    return <div className={styles.item}>
                        <div className={`${styles.image} bg-${item.imageBackgroundColor}`}>
                            <img src={item.image} color={`color-${item.imageColor}`} alt={"Mengapa kami " + item.title} />
                        </div>
                        <div className={styles.content}>
                            <Heading variant='h5'>{item.title}</Heading>
                            <Text>{item.notes}</Text>
                        </div>
                    </div>
                })}
            </div>
        </div>
        <div className={styles.rightSection}>
            <div className={styles.topImage}>
                <div className={styles.topLeftImage}> </div>
                <div className={styles.topRightImage}> </div>
            </div>
            <div className={styles.bottomImage}>
                <div className={styles.bottomLeftImage}> </div>
                <div className={styles.bottomRightImage}> </div>
            </div>
        </div>
    </div>;
}

export default WhyUsSection;
