import { faEnvelope, faLocationDot } from "@fortawesome/pro-light-svg-icons";
import { AboutUsProps } from "./aboutus";
import { MenuItemProps } from "./menu";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import AboutUs from "./reachus";

type FooterDataProps = {
    menus: MenuItemProps[];
    aboutus: AboutUsProps[];
}

const FooterData: FooterDataProps = {
    menus: [
        {
            key: 'home',
            text: 'Home',
            url: '#home'
        },
        {
            key: 'contactus',
            text: 'Contact Us',
            url: '#about'
        },
        {
            key: 'requestQuote',
            text: 'Request Quote',
            url: '#contact'
        },
        {
            key: 'whyUs',
            text: 'Why Us?',
            url: '#advantages'
        },
        {
            key: 'ourServices',
            text: 'Our Services',
            url: '#services'
        },
        {
            key: 'ourClients',
            text: 'Our Clients',
            url: '#clients'
        }
    ],
    aboutus: [
        AboutUs.email,
        AboutUs.office,
        {
            key: 'branchOffice',
            title: 'Kator Cabang (Jakarta)',
            value: 'Jl. Cipinang Cimpedak I No. 18A, Kec. Jatinegara, Jakarta Timur',
            icon: faLocationDot,
            url: 'https://www.google.com/search?sca_esv=555929706&tbs=lf:1,lf_ui:2&tbm=lcl&q=jejak+logistik+nusantara+(jakarta)&rflfq=1&num=10&rllag=-6236923,106872121,0&rldimm=11302787116949168703&ved=2ahUKEwia57qk9dSAAxX1bmwGHZzcDIoQu9QIegQIHhAM'
        },
        {
            ...AboutUs.whatsapps[0],
            value: AboutUs.whatsapps[0].number,
            icon: faWhatsapp
        }
    ]
}

export default FooterData;