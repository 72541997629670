import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import HeroSection from './sections/hero';
import ServiceSection from './sections/services';
import CheckRateSection from './sections/checkrate';
import ClientsSection from './sections/clients';
import WhyusSection from './sections/whyus';
import AreYouSureSection from './sections/areyousure';
import { IPublicContactResourceshort } from '../../../../props/users/contact';
import { IPublicOfficeResourceshort } from '../../../../props/users/office';
import ReachUsSection from './sections/reachus';

type TypeLandingPageProps = {
  children?: React.ReactNode;
  contacts: IPublicContactResourceshort[];
  offices: IPublicOfficeResourceshort[];
  smeDivRefs: React.RefObject<HTMLDivElement>[]
};

const LandingPage: React.FC<TypeLandingPageProps> = (props) => {

  const [offices, setOffices] = useState<IPublicOfficeResourceshort[]>(props.offices)

  useEffect(() => {
    setOffices(props.offices);
    return () => { }
  }, [props.offices])

  const [contacts, setContacts] = useState<IPublicContactResourceshort[]>(props.contacts)

  useEffect(() => {
    setContacts(props.contacts)
    return () => { }
  }, [props.contacts])

  return (
    <>
      <div className={styles.content}>
        <HeroSection />
      </div>
      <div ref={props.smeDivRefs[0]} className={styles.content} id='p-ourservices-section'>
        <ServiceSection />
      </div>
      <div ref={props.smeDivRefs[1]} className={styles.content} id='p-whyus-section'>
        <WhyusSection />
      </div>
      <div ref={props.smeDivRefs[2]} className={styles.content} id='p-checkrate-section'>
        <CheckRateSection />
      </div>
      <div ref={props.smeDivRefs[3]} className={styles.content} id='p-ourclients-section'>
        <ClientsSection />
      </div>
      <div ref={props.smeDivRefs[4]} className={styles.content} id='p-areyousure-section'>
        <AreYouSureSection />
      </div>
      <div style={{ width: '100%' }} className={'bg-image bg-reachus'}>
        <section className={styles.content} id={'p-reachus-section'}>
          <ReachUsSection contacts={props.contacts} offices={props.offices} />
        </section>
        <div className={`bg-backdrop`}></div>
      </div>
    </>
  )
};

export default LandingPage;