import React from 'react';
import styles from './styles.module.scss'
import Heading from '../../../../../../components/text/heading';
import Text from '../../../../../../components/text/text';
import CheckRateImage from '../../../../../../assets/images/check-rate.webp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import GlobalConfig from '../../../../../../config';

type TypeRateSectionProps = {
    children?: React.ReactNode;
    ref?: React.LegacyRef<HTMLDivElement>
};

const CheckRateSection: React.FC<TypeRateSectionProps> = (props) => {
    return (
        <section className={styles.container} >
            <div className={styles.content}>
                <div className={styles.sideLeft}>
                    <Heading variant='h2' className={styles.text}>SIAP UNTUK KIRIM BARANG? CEK HARGA PENGIRIMAN SEKARANG JUGA !!!</Heading>
                    <Text variant={'secondary'} className={styles.text}>Cek harga pengiriman termurah anda sekarang juga kepada para ahli logistik kami. Para ahli logistik kami akan dengan senang membantu anda.</Text>
                    <div style={{ display: 'flex', marginTop: 20 }}>
                        <a style={{ display: 'flex', gap: 10, background: 'white', alignItems: 'center' }}
                            className={'button'}
                            id={'checkrate_button'}
                            href={`https://wa.me/${GlobalConfig.mainPhoneNumber}?text=Halo%2C%20saya%20mau%20menanyakan%20rate%20pengiriman%20menggunakan%20Jejak%20Logistik`}
                            target="_blank" >
                            <FontAwesomeIcon icon={faWhatsapp} color='black' fontSize={20} />
                            <Text className={styles.btnText}>Cek Ongkos Kirim</Text>
                        </a>
                    </div>
                </div>

                <div className={styles.sideRight}>
                    <img src={CheckRateImage} alt="requestQuote" style={{ borderRadius: 20 }} />
                </div>
            </div>
        </section>
    )
};

export default CheckRateSection;