import React, { PropsWithChildren, useEffect, useState } from 'react';

import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import AboutUs from '../../manifests/reachus';
import Heading from '../text/heading';
import Text from '../text/text';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { IPublicContactResourceshort } from '../../props/users/contact';
import GlobalConfig from '../../config';
import { ContactType } from '../../pages/home';

const WhatsappContact: React.FC<{
    contacts: IPublicContactResourceshort[]
}> = (props) => {
    const [openList, setOpenList] = React.useState<boolean>(false);

    const [contacts, setContacts] = useState<IPublicContactResourceshort[]>(props.contacts)

    useEffect(() => {
        setContacts(props.contacts)
        return () => { }
    }, [props.contacts])

    return <div>
        <a className={styles.iconContainer} onClick={() => setOpenList(true)}>
            <FontAwesomeIcon icon={faWhatsapp} fontSize={40} className={styles.icon} />
        </a>
        {openList ? <div className={styles.listContainer}>
            <div className={styles.header}>
                <Heading variant={'h5'}>Hubungi Kami</Heading>
                <a className={styles.closeIcon} onClick={() => setOpenList(false)}><FontAwesomeIcon icon={faXmark} fontSize={20} /></a>
            </div>
            <ul className={styles.lists}>
                {contacts.map((au) => {
                    return <li className={styles.item}>
                        <a className={styles.link} href={GlobalConfig.assetsHostname + '/' + au.profile_picture_url} target={'_blank'}>
                            <img src={GlobalConfig.assetsHostname + '/' + au.profile_picture_url} className={styles.profileImage} alt={"Kotak tim kami " + au.name + " via whatsapp"} />
                            <div className={styles.details}>
                                <Heading variant={'h6'} renderAsLabel={true}>{au.name}</Heading>
                                <Text>{au.phone_number}</Text>
                            </div>
                        </a>
                    </li>
                })}
            </ul>
        </div> : null}
    </div>
}

export default WhatsappContact;
