import React from 'react';

import styles from './styles.module.scss';
import Heading from '../../../../components/text/heading';
import Text from '../../../../components/text/text';
import OurServices from '../../../../manifests/services';

type ServicesSectionProps = {};

const ServicesSection: React.FC<ServicesSectionProps> = (props) => {
    return <div className={styles.container}>
        <div className={styles.leftSection}>
            <div className={styles.header}>
                <Heading variant='h2'>Kelebihan Kami</Heading>
                <div className={styles.indicator}> </div>
            </div>
            <div className={styles.body}>
                {OurServices.map((item) => {
                    return <div className={styles.item}>
                        <div className={`${styles.image} bg-${item.imageBackgroundColor}`}>
                            <img src={item.image} color={`color-${item.imageColor}`} alt={"Servis kami " + item.title} />
                        </div>
                        <Heading variant='h6' renderAsLabel={true}>{item.title}</Heading>
                        <div className={styles.divider}></div>
                        <Text>{item.notes}</Text>
                    </div>
                })}
            </div>
        </div>
        <div className={styles.rightSection}> </div>
    </div>;
}

export default ServicesSection;
